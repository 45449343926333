import qs from 'qs';
import {Epic, ofType} from 'redux-observable';
import {map, tap, filter, mergeMap} from 'rxjs/operators';
import {combineLatest, concat, from, of} from 'rxjs';

import {log} from '@chancer/common/lib/utils/Log';
import {removeSpaces} from '@chancer/common/lib/utils/StringUtils';

import {ActionType, IAction, noOpAction} from '../../actions/Actions';
import {
  getIsViewStateSet,
  getIsCompetitionReadyToSetupView,
  COMP_DETAILS_INDEX,
  HOME_PAGE_INDEX,
} from '../../selectors/startup/StartupSelectors';

import {IAppState, IViewState} from '../../state/AppState';
import {
  followedEntry,
  setIntialViewState,
} from '../../actions/startup/StartupActions';
import {
  getQuestions,
  getUsersCompetitionEntry,
  getOutstandingQuestions,
  getCompetitionStatus,
} from '../../selectors/competitions/CompetitionSelectors';
import {getIsLoggedIn} from '../../selectors/auth/AuthSelectors';
import {getUserName} from '../../selectors/user/AppUserSelectors';
import {getCompetitionId} from '../../selectors/competitions/CompetitionSelectors';
import {browserHistory} from '../../components/History';
import {getFollowingInComp} from '../../selectors/leaderboard/AppLeaderboardSelectors';
import {
  getUserByIdStream,
  getUserEntryByIdStream,
} from '@chancer/common/lib/utils/UserHttpUtils';
import {
  TFirebaseCompEntry,
  TFirebaseUser,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {getConfig} from '../../selectors/config/ConfigSelectors';
import {CompStatus} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {AppPlatform} from '@chancer/common/lib/interfaces/client/ClientInterfaces';

export const setInitialViewStateEpic: Epic<
  IAction<any>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(
      ActionType.AUTH_MESSAGE,
      ActionType.USER_MESSAGE,
      ActionType.USER_ENTRY_MESSAGE,
      ActionType.QUESTIONS_MESSAGE,
      ActionType.COMPETITION_MESSAGE,
      ActionType.COMPETITION_STATUS_MESSAGE,
    ),
    filter(() => !getIsViewStateSet(state$.value)),
    filter(() => getIsCompetitionReadyToSetupView(state$.value)),
    map(() => createInitialViewState(state$.value)),
    tap((viewState) => log.info('[setInitialViewStateEpic]', viewState)),
    mergeMap((viewState) =>
      concat(
        of(setIntialViewState(viewState)),
        of('').pipe(
          tap(() => {
            if (getConfig(state$.value).platform === AppPlatform.ANDROID) {
              // Android needs a push here otherwise the back button does not work
              browserHistory.push({
                pathname: `/${viewState.currentPageIndex}`,
                search: window.location.search,
              });
            } else {
              browserHistory.replace({
                pathname: `/${viewState.currentPageIndex}`,
                search: window.location.search,
              });
            }
          }),
          filter(() => false),
          map(() => noOpAction()),
        ),
      ),
    ),
  );

const createInitialViewState = (state: IAppState): IViewState => {
  const questions = getQuestions(state);
  const questionsRemaining = getOutstandingQuestions(state);
  const entryStatus = getUsersCompetitionEntry(state);
  const userName = getUserName(state);
  const isLoggedIn = getIsLoggedIn(state);
  const compStatus = getCompetitionStatus(state);

  const viewState: IViewState = {
    currentPageIndex: -1,
    isAuthRequired: false,
    isSignupNameRequired: false,
    isEntryRequired: false,
    isQuestionsRequired: false,
    questionsToAnswer: questionsRemaining,
  };

  const direct = getAllQuerystringParams().mode === 'direct';

  if (!questions) {
    log.warning(
      '[createInitialViewState] No questions, somethings gone wrong, return state with no page index set yet',
    );
    return viewState;
  }

  viewState.isEntryRequired =
    (compStatus === CompStatus.OPEN || compStatus === CompStatus.INPLAY) &&
    (entryStatus === null || questionsRemaining.length > 0);

  if (!isLoggedIn) {
    viewState.isAuthRequired = viewState.isEntryRequired;
  }
  if (!userName || removeSpaces(userName) === '') {
    viewState.isSignupNameRequired = viewState.isEntryRequired;
  }

  viewState.isQuestionsRequired = questionsRemaining.length > 0;

  if (
    (viewState.isAuthRequired || viewState.isSignupNameRequired) &&
    questions.length > 0 &&
    questionsRemaining.length === 0
  ) {
    // User has played through the comp, the question screens won't be rendered, show them the auth/sign in screen
    log.info('[createInitialViewState] Render auth/sign in');
    viewState.currentPageIndex = HOME_PAGE_INDEX + 1;
    return viewState;
  } else if (entryStatus === null && questionsRemaining.length > 0 && !direct) {
    // User not entered comp. Show home page
    log.info('[createInitialViewState] Render questions, home page');
    viewState.currentPageIndex = HOME_PAGE_INDEX;
    return viewState;
  } else if (questionsRemaining.length > 0) {
    log.info('[createInitialViewState] Render questions, next question');
    // User in the middle of playing through the comp
    viewState.currentPageIndex = HOME_PAGE_INDEX + 1;
    return viewState;
  } else {
    log.info('[createInitialViewState] Default');
    // User in the middle of playing through the comp
    viewState.currentPageIndex = COMP_DETAILS_INDEX;
    return viewState;
  }
};

export const getUsersFollowingInCompAnswersEpic: Epic<
  IAction<any>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.USER_FOLLOWING_MESSAGE, ActionType.LEADERBOARD_MESSAGE),
    map(() => getFollowingInComp(state$.value)),
    filter((following) => following.length > 0),
    filter((_) => getCompetitionId(state$.value) !== null),
    map((following) => ({
      following,
      streamForUser: getUserByIdStream(getConfig(state$.value).gameUrl),
      streamForEntry: getUserEntryByIdStream(
        getConfig(state$.value).gameUrl,
        getCompetitionId(state$.value) as string,
      ),
    })),
    mergeMap((data) =>
      from(data.following).pipe(
        mergeMap((followingId, _index) =>
          combineLatest(
            data.streamForUser(followingId),
            data.streamForEntry(followingId),
            (user: TFirebaseUser, entry: TFirebaseCompEntry) =>
              followedEntry(user, entry),
          ),
        ),
      ),
    ),
  );

const getAllQuerystringParams = () => {
  const queryString = window.location.search;
  if (queryString && queryString.length > 0) {
    return qs.parse(queryString.substring(1), {depth: 0}) as {
      [key: string]: string;
    };
  }
  return {};
};
