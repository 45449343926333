import React, {ComponentType} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  AppNavigation,
  GameScreenNames,
} from '@chancer/common/lib/app/AppMessage';
import {
  ClientStatus,
  ICompSummaryAndEntry,
} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {
  AppPlatform,
  IAnalyticsPayload,
} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {
  TFirebaseComp,
  TFirebaseCompCounts,
  TFirebaseCompEntry,
  TFirebaseCompSummary,
  TFirebaseQuestion,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {
  TLeaderboard,
  TMediaEntry,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {
  getShareOverlay,
  getSimpleMessageOverlayFromApp,
} from '@chancer/common/lib/interfaces/overlay/OverlayCreator';
import {IOverlay} from '@chancer/common/lib/interfaces/overlay/OverlayInterfaces';
import log from '@chancer/common/lib/utils/Log';
import {PrimaryButton} from '@chancer/components/lib/Button/PrimaryButton';
import {ShareButton} from '@chancer/components/lib/Button/ShareButton';
import {CompDetails} from '@chancer/components/lib/FeaturedCompetition/CompDetails';
import {PrizeCard} from '@chancer/components/lib/Leaderboard/PrizeCard';
import {CompositedViewStyle} from '@chancer/components/lib/Styles/StyleTypes';
import {IAction} from '../../actions/Actions';
import {trackSelectContent} from '../../actions/analytics/AnalyticsActions';
import {sendNavigate} from '../../actions/messages/MessageActions';
import {addOverlay} from '../../actions/overlay/OverlayActions';
import {
  getCompetitionChatUnreadCount,
  getCompetitionSummary,
  getOutstandingQuestions,
  getUpcomingCompetitions,
} from '../../selectors/competitions/CompetitionSelectors';
import {getLeaderboardConfig} from '../../selectors/leaderboard/AppLeaderboardSelectors';
import {assertType} from '@chancer/common/lib/utils/TypeUtils';
import {SectionSubTitle} from '@chancer/components/lib/SectionSubTitle/SectionSubTitle';
import '../Application.scss';
import {useCurrentScreenView} from '../Core/Hooks';
import {MediaView} from '../Core/Media/MediaView';
import {ScreenContainer} from '../Screen/ScreenContainer';
import './CompetitionScreen.scss';
import {createCompetitionVendor} from '@chancer/common/lib/utils/CompetitionUtils';

const MAX_ENTRIES: number = 9500;

interface IProps {
  mute: boolean;
  currentIndex: number;
  pageIndex: number;
  comp: TFirebaseComp;
  compStatus: ClientStatus;
  competitionCounts: TFirebaseCompCounts;
  playEnabled: boolean;
  entryStatus: TFirebaseCompEntry | null;
  windowHeight: number;
  appPlatform: AppPlatform;
  safeAreaTopPadding: number;
  safeAreaBottomPadding: number;
  onPlayNow: () => void;
  onCompDetails: () => void;
  onAnswers: () => void;
  onMute: () => void;
}

interface IStateProps {
  compSummary: TFirebaseCompSummary | null;
  leaderboardConfig: TLeaderboard;
  upcomingGames: ICompSummaryAndEntry[];
  outstandingQuestions: TFirebaseQuestion[];
  unreadChatCount: number;
}

interface IDispatchProps {
  onAddOverlay: (overlay: IOverlay) => void;
  onAnalytics: (action: IAction<IAnalyticsPayload>) => void;
  onNavigate: (nav: AppNavigation, path?: string) => void;
}

export const CompetitionScreen: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const isActive: boolean = props.currentIndex === props.pageIndex;
  useCurrentScreenView(GameScreenNames.COMPETITION, isActive, dispatch);

  return (
    <CompetitionScreenView
      {...props}
      leaderboardConfig={useSelector(getLeaderboardConfig)}
      compSummary={useSelector(getCompetitionSummary)}
      upcomingGames={useSelector(getUpcomingCompetitions)}
      outstandingQuestions={useSelector(getOutstandingQuestions)}
      unreadChatCount={useSelector(getCompetitionChatUnreadCount)}
      onAddOverlay={(overlay: IOverlay) => dispatch(addOverlay(overlay))}
      onAnalytics={(action) => dispatch(action)}
      onNavigate={(nav, path) => dispatch(sendNavigate(nav, path))}
    />
  );
};

export const CompetitionScreenView: React.FC<
  IProps & IStateProps & IDispatchProps
> = (props) => {
  const userHasEntered = props.entryStatus !== null;

  const _playNow = () => {
    if (props.competitionCounts.entriesCount < MAX_ENTRIES) {
      props.onAnalytics(
        trackSelectContent('competition_screen__play-now-button'),
      );
      props.onPlayNow();
    } else {
      props.onAddOverlay(
        getSimpleMessageOverlayFromApp({
          title: 'Chancer is popular!',
          message: `We’re sorry but we’ve had to limit the number of players to ${MAX_ENTRIES} for this week. Please come back next week for unlimited fun!`,
        }),
      );
    }
  };

  const _onShare = () => {
    props.onAnalytics(trackSelectContent('competition_screen__share'));
    props.onAddOverlay(getShareOverlay());
  };

  const _onMoreInfoSelected = (info: string) => {
    props.onAnalytics(trackSelectContent('competition_screen__more-info'));
    log.warning('Need to show more info');
  };

  const _onGoToLeaderboard = () => {
    props.onAnalytics(trackSelectContent('competition_screen__leaderboard'));
    props.onCompDetails();
  };

  const showPlayButton =
    props.outstandingQuestions.length > 0 &&
    (props.compStatus === ClientStatus.IN_PLAY ||
      props.compStatus === ClientStatus.OPEN ||
      props.compStatus === ClientStatus.PRE_START);

  return (
    <ScreenContainer
      mute={props.mute}
      currentIndex={props.currentIndex}
      pageIndex={props.pageIndex}
      windowHeight={props.windowHeight}
      isQuestion={false}
      media={props.comp.media}
      safeAreaTopPadding={props.safeAreaTopPadding}
      onMute={props.onMute}>
      {/* <IntersectionRoot threshold={threshold}> */}
      <div className="competition__container">
        <div
          className="competition__top-container"
          style={{
            height:
              props.windowHeight -
              props.safeAreaTopPadding -
              props.safeAreaBottomPadding -
              104,
            marginTop: props.safeAreaTopPadding,
          }}>
          {props.compSummary && (
            <CompDetails
              competition={assertType<ICompSummaryAndEntry>({
                summary: props.compSummary,
                entry: props.entryStatus,
                outstandingQuestions: props.outstandingQuestions.length,
                vendor: createCompetitionVendor(props.compSummary),
              })}
              outstandingQuestions={props.outstandingQuestions.length}
              clientStatus={props.compStatus}
            />
          )}
          {showPlayButton ? (
            <PrimaryButton
              style={styles.buttonsStyle}
              disabled={!props.playEnabled}
              label="Play Now"
              onPress={_playNow}
            />
          ) : (
            <ShareButton
              style={styles.buttonsStyle}
              label="Share the game"
              onPress={_onShare}
            />
          )}
        </div>
        <div className={'competition__bottom-container'}>
          <PrizeCard
            comp={props.comp}
            leaderboardConfig={props.leaderboardConfig}
            userHasEntered={userHasEntered}
            MediaComponent={MediaView as ComponentType<{media: TMediaEntry}>}
            onMoreInfo={_onMoreInfoSelected}
            onGoToLeaderboard={_onGoToLeaderboard}
          />
          <SectionSubTitle
            style={styles.subSectionTitle}
            title=""
            subTitle="How to play"
          />
          <div
            className="competition__details application__details-styling"
            dangerouslySetInnerHTML={{__html: props.comp.details}}
          />
          {props.safeAreaBottomPadding > 0 && (
            <div
              style={{
                width: '100%',
                height: `${props.safeAreaBottomPadding}px`,
              }}
            />
          )}
        </div>
      </div>
      {/* </IntersectionRoot> */}
    </ScreenContainer>
  );
};

// const threshold = [0, 0.8];

const styles: {[key: string]: CompositedViewStyle} = {
  buttonsStyle: {
    marginTop: 32,
    marginHorizontal: 36,
  },
  newQuestionsLabel: {
    marginRight: 8,
  },
  subSectionTitle: {
    marginLeft: 0,
    marginBottom: -16,
  },
};
