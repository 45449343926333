import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {COLOR_YELLOW, FONT_TITLE} from '../Styles/DesignSystem-chancer';

interface IProps {
  isWinner: boolean;
  children: React.ReactNode;
}

export const WinnerEntrantItemWrapper: React.FC<IProps> = (props) => {
  const {isWinner, children} = props;
  return isWinner ? (
    <View style={styles.winnerContainer}>
      <View style={styles.winnerHeader}>
        <Text style={styles.winnerCopy}>WINNER</Text>
      </View>
      {children}
    </View>
  ) : (
    children
  );
};

const styles = StyleSheet.create({
  winnerContainer: {
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: COLOR_YELLOW,
    borderRadius: 3,
  },
  winnerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 56,
    backgroundColor: COLOR_YELLOW,
    padding: 8,
  },
  winnerCopy: {
    fontFamily: FONT_TITLE,
    fontSize: 20,
  },
});
